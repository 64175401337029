// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import { MotionLazyContainer } from "./components/animate";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotificationProvider";
import useAuth from "./hooks/useAuth";
import axios from "./utils/axios";

// import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

export default function App() {
	const { logout } = useAuth();
	
	// const { enqueueSnackbar } = useSnackbar();
	// axios.setSnackbar(enqueueSnackbar);
	axios.setLogout(logout);
	return (
		<ThemeProvider>
			<NotistackProvider>
				<MotionLazyContainer>
					<ProgressBarStyle />
					<ScrollToTop />
					<BaseOptionChartStyle />
					<Router />
				</MotionLazyContainer>
			</NotistackProvider>
		</ThemeProvider>
	);
}
