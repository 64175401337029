import {Suspense, lazy} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router-dom';
// layouts
import MainLayout from './layouts/main';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import AuthGuard from './Guards/AuthGuard';
import Loader from './components/LoadingScreen';
import GuestGuard from './Guards/GuestGuard';
import {PATH_AFTER_LOGIN} from './config';

// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const {pathname} = useLocation();

	return (
		<Suspense fallback={<Loader isDashboard={pathname.includes('/dashboard')} />}>
			<Component {...props} />
		</Suspense>
	);
};
export default function Router() {
	return useRoutes([
		{path: '*', element: <Navigate to="/404" replace />},
		{
			path: '/',
			element: <MainLayout />,
			children: [
				{element: <Order />, index: true},
				// {path: 'order', element: >},
			],
		},
		{
			path: '/auth',

			children: [
				{
					path: 'login',
					element: (
						<GuestGuard>
							<Login />
						</GuestGuard>
					),
				},
				{
					path: 'register',
					element: (
						<GuestGuard>
							<Register />
						</GuestGuard>
					),
				},
				{
					path: 'forgot-password',
					element: (
						<GuestGuard>
							<ForgotPassword />
						</GuestGuard>
					),
				},
				{
					path: 'reset-password',
					element: (
						<GuestGuard>
							<ResetPassword />
						</GuestGuard>
					),
				},
			],
		},
		{
			path: '/dashboard',
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{
					element: <Navigate to={PATH_AFTER_LOGIN} replace />,
					index: true,
				},
				{path: 'app', element: <DashboardApp />},
				{path: 'settings', element: <Settings />},
				{path: 'user', element: <User />},
				{
					path: 'instant-quote',
					element: <InstantQuotes />,
				},
				{
					path: 'instant-quote/spare-parts',
					element: <SpareParts />,
				},
				{
					path: 'instant-quote/batteries',
					element: <Batteries />,
				},
				{
					path: 'instant-quote/lubricants',
					element: <DashboardLubricants />,
				},
				{
					path: 'instant-quote/tyres',
					element: <DashboardTyres />,
				},
			],
		},
		{
			path: '*',
			element: <LogoOnlyLayout />,
			children: [
				{
					path: '*',
					element: <Navigate to="/404" replace />,
				},
				{path: '404', element: <NotFound />},
				{path: 'parts', element: <SparePartsOrder />},
				{path: 'battery', element: <Battery />},
				{path: 'tyres', element: <Tyres />},
				{path: 'lubricants', element: <Lubricants />},
			],
		},
	]);
}

//  Auth
const Login = Loadable(lazy(() => import('./pages/Login')));
const Register = Loadable(lazy(() => import('./pages/Register')));
const ForgotPassword = Loadable(lazy(() => import('./pages/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('./pages/ResetPassword')));

const DashboardApp = Loadable(lazy(() => import('./pages/DashboardApp')));
const Settings = Loadable(lazy(() => import('./pages/UserAccount')));
const User = Loadable(lazy(() => import('./pages/User')));
const InstantQuotes = Loadable(lazy(() => import('./pages/InstantQuotes')));

const NotFound = Loadable(lazy(() => import('./pages/Page404')));
const Order = Loadable(lazy(() => import('./pages/Order')));
const SparePartsOrder = Loadable(lazy(() => import('./pages/order/SpareParts')));
const Battery = Loadable(lazy(() => import('./pages/order/Battery')));
const Tyres = Loadable(lazy(() => import('./pages/order/Tyres')));
const Lubricants = Loadable(lazy(() => import('./pages/order/Lubricants')));

// Instant Quote
const SpareParts = Loadable(lazy(() => import('./pages/quotes/SpareParts')));
const Batteries = Loadable(lazy(() => import('./pages/quotes/Battery')));
const DashboardLubricants = Loadable(lazy(() => import('./pages/quotes/Lubricants')));
const DashboardTyres = Loadable(lazy(() => import('./pages/quotes/Tyres')));
