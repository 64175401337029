import Iconify from '../../components/Iconify';

const ICON_SIZE = {
	width: 22,
	height: 22,
};

const menuConfig = [
	{
		title: 'Products',
		icon: (
			<Iconify
				icon={'streamline:shopping-bag-hand-bag-1-shopping-bag-purse-goods-item-products'}
				{...ICON_SIZE}
			/>
		),
		path: '/pages',
		children: [
			{
				// subheader: 'Quotes',
				items: [
					{title: 'Fuel Wallet', path: 'https://fuelwallet.mycarviva.com/'},
					{title: 'Auto parts', path: '/parts'},
					{title: 'Auto care', path: '/tyres'},
					{title: 'Auto blog', path: 'http://autolife.mycarviva.com/'},
					{title: 'Connected cars', path: '/battery'},
				],
			},
		],
	},
	{
		title: 'Home',
		icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
		path: '/',
	},
	// {
	// 	title: 'Login',
	// 	icon: <Iconify icon={'eva:log-in-outline'} {...ICON_SIZE} />,
	// 	path: 'auth/login',
	// },
	// {
	// 	title: 'Register',
	// 	icon: <Iconify icon={'eva:person-add-outline'} {...ICON_SIZE} />,
	// 	path: 'auth/register',
	// },
];

export default menuConfig;
