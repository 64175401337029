import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {NavLink as RouterLink, useLocation} from 'react-router-dom';
// @mui
import {alpha, styled} from '@mui/material/styles';
import {
	List,
	Link,
	Drawer,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	Box,
	Collapse,
} from '@mui/material';
// config
import {NAVBAR} from '../../config';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import {IconButtonAnimate} from '../../components/animate';

// import { NavSectionVertical } from '../../components/nav-section';
// import NavSection from 'src/components/NavSection';
import {NavSectionVertical} from 'src/components/nav-section';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({theme}) => ({
	...theme.typography.body2,
	height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
	textTransform: 'capitalize',
	color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

MenuMobile.propTypes = {
	isOffset: PropTypes.bool,
	isHome: PropTypes.bool,
	navConfig: PropTypes.array,
};

export default function MenuMobile({isOffset, isHome, navConfig}) {
	const {pathname} = useLocation();

	const [open, setOpen] = useState(false);

	const [drawerOpen, setDrawerOpen] = useState(false);

	useEffect(() => {
		if (drawerOpen) {
			handleDrawerClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	return (
		<>
			<IconButtonAnimate
				onClick={handleDrawerOpen}
				sx={{
					ml: 1,
					...(isOffset && {color: 'text.primary'}),
				}}
			>
				<Iconify icon={'eva:menu-2-fill'} />
			</IconButtonAnimate>

			<Drawer
				open={drawerOpen}
				onClose={handleDrawerClose}
				ModalProps={{keepMounted: true}}
				PaperProps={{sx: {pb: 5, width: '100%', top: 0, height: 'auto'}}}
				anchor="top"
				hideBackdrop
			>
				<Scrollbar>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Logo sx={{mb: 4, pl: 2, pt: 3}} />
						<IconButtonAnimate
							onClick={handleDrawerClose}
							sx={{
								mr: 2,
								...(isOffset && {color: 'text.primary'}),
							}}
						>
							<Iconify icon={'material-symbols:close'} />
						</IconButtonAnimate>
					</Box>

					{/* <img src={require('src/assets/logoi.png')} width={'188'} height={'52'} alt="Carviva" /> */}

					<List>
						{navConfig.map((link) => (
							<MenuMobileItem key={link.title} item={link} isOpen={open} onOpen={handleOpen} />
						))}
					</List>
				</Scrollbar>
			</Drawer>
		</>
	);
}

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
	isOpen: PropTypes.bool,
	item: PropTypes.shape({
		children: PropTypes.array,
		icon: PropTypes.any,
		path: PropTypes.string,
		title: PropTypes.string,
	}),
	onOpen: PropTypes.func,
};

function MenuMobileItem({item, isOpen, onOpen}) {
	const {title, path, icon, children} = item;

	if (children) {
		return (
			<>
				<ListItemStyle onClick={onOpen}>
					<ListItemIcon>{icon}</ListItemIcon>
					<ListItemText disableTypography primary={title} />
					<Iconify
						icon={isOpen ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
						sx={{width: 16, height: 16, ml: 1}}
					/>
				</ListItemStyle>

				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					<Box sx={{display: 'flex', flexDirection: 'column-reverse'}}>
						<NavSectionVertical
							al
							navConfig={children}
							// sx={{
							// 	'& .MuiList-root:last-of-type .MuiListItemButton-root': {
							// 		height: 200,
							// 		backgroundSize: 'cover',
							// 		backgroundPosition: 'center',
							// 		bgcolor: 'background.neutral',
							// 		backgroundRepeat: 'no-repeat',
							// 		backgroundImage:
							// 			'url(https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png)',
							// 		'& > *:not(.MuiTouchRipple-root)': {display: 'none'},
							// 	},
							// }}
						/>
						{/* <NavSection
							navConfig={children}
							sx={{
								'& .MuiList-root:last-of-type .MuiListItemButton-root': {
									height: 200,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									bgcolor: 'background.neutral',
									backgroundRepeat: 'no-repeat',
									backgroundImage:
										'url(https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png)',
									'& > *:not(.MuiTouchRipple-root)': {display: 'none'},
								},
							}}
						/> */}
					</Box>
				</Collapse>
			</>
		);
	}

	if (title === 'Documentation') {
		return (
			<ListItemStyle href={path} target="_blank" rel="noopener" component={Link}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText disableTypography primary={title} />
			</ListItemStyle>
		);
	}

	return (
		<ListItemStyle
			to={path}
			component={RouterLink}
			end={path === '/'}
			sx={{
				'&.active': {
					color: 'primary.main',
					fontWeight: 'fontWeightMedium',
					bgcolor: (theme) =>
						alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
				},
			}}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText disableTypography primary={title} />
		</ListItemStyle>
	);
}
