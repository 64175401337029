import axios from "axios";
import storage from "./storage";


// ----------------------------------------------------------------------

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

function refreshUserToken(refreshToken) {
	return axios.post(
		`${process.env.REACT_APP_BASE_URL}/api/v1/login/refresh`,
		{},
		{
			headers: {
				Authorization: `Bearer ${refreshToken}`,
			},
		}
	);
}

axiosInstance.interceptors.request.use((config) => {
	const tokens = storage.get("ar");

	if (tokens) {
		const { accessToken } = tokens;
		if (!accessToken) return config;
		const requestConfig = {
			...config,
			headers: {
				...config.headers,
				Authorization: `Bearer ${accessToken}`,
			},
		};
		return requestConfig;
	}
	return config;
});

let logout;
axiosInstance.setLogout = (fn) => (logout = fn);
axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const tokens = storage.get("ar");
		if (tokens) {
			if (
				error.response &&
				error.response?.status === 401 &&
				tokens?.refreshToken
			) {
				try {
					const refreshTokenResponse =
						await refreshUserToken(tokens?.refreshToken);
					const {
						data: { accessToken },
					} = refreshTokenResponse;

					storage.set("ar", { accessToken: accessToken, refreshToken: tokens?.refreshToken });
					// localStorage.setItem("accessToken", accessToken);
					const refreshedConfig = {
						...error.config,
						headers: {
							...error.config.headers,
							Authorization: `Bearer ${accessToken}`,
						},
					};
					const refreshedResponse = await axios.request(
						refreshedConfig
					);
					return refreshedResponse;
				} catch (error) {
					storage.clear()
					logout();
				}
			}
		} else {
			storage.clear()
			logout();
		}

		if (

			error.response.status === 401 &&
			window.location.pathname !== "/login"
		) {
			storage.clear()
			logout();
		}
		return Promise.reject(
			(error.response && error.response.data) ||
				"Something went wrong"
		);
	}
);

export default axiosInstance;
