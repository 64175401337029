import React from 'react';
import {
	Typography,
	Stack,
	Dialog,
	DialogContent,
	DialogTitle,
	AppBar,
	Toolbar,
	IconButton,
    Link,
} from '@mui/material';

// import SocialsButton from '../../components/SocialsButton';
// import { Link  as RouterLink} from 'react-router-dom';
// ----------------------------------------------------------------------
import Slide from '@mui/material/Slide';
import Iconify from 'src/components/Iconify';
import useResponsive from 'src/hooks/useResponsive';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrivacyPolicy({openDialog1, handleClick1}) {
	const isDesktop = useResponsive('up', 'md');
	return (
		<Dialog
			open={openDialog1}
			TransitionComponent={Transition}
			// keepMounted
			onClose={handleClick1}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			fullScreen
			keepMounted
			// fullWidth
			maxWidth="lg"
		>
			<AppBar sx={{position: 'relative'}}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClick1} aria-label="close">
						<Iconify icon={'material-symbols:close'} />
					</IconButton>
					<Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
						Privacy Policy
					</Typography>
				</Toolbar>
			</AppBar>

			<DialogTitle sx={{textAlign: 'center'}}>
				CARVIVA PRIVACY POLICY
				<br />
			</DialogTitle>
			<Typography sx={{textAlign: 'center'}} variant="p">
				Effective date: 2023-03-03
			</Typography>
			<DialogContent
				sx={{
					width: isDesktop ? '100%' : '100%',
					paddingLeft: isDesktop ? '10%' : '',
					paddingRight: isDesktop ? '10%' : '',
				}}
			>
				<Stack gap={2}>
					<Typography variant="p">
						By viewing mycarviva.com websites or downloading Carviva App, you agree to the terms of
						this Privacy Policy. Please do not download the app or use mycarviva.com website if you
						do not agree to these privacy policy terms. Carviva Technologies Limited reserves the
						right to change the Privacy Policy from time to time. Your use of this site will be
						subject to the most current version of the Privacy Policy at the time of such use.{' '}
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>1. Introduction</Typography>
					<Typography variant="p" paddingLeft={2}>
						Welcome! Carviva Fuel Wallet and Carviva Mobile Application are operated by Carviva
						Technologies Limited (CTL) CTL (“us”, “we”, or “our”) is responsible for service
						platforms such as Carviva Mobile App, Carviva Fuel Wallet; fuelwallet.mycarviva.com and
						mycarviva.com website (hereinafter referred to as “Service”). Our Privacy Policy governs
						your visit to fuelwallet.mycarviva.com, mycarviva.com and the use of Carviva Mobile App,
						and explains how we collect, safeguard, and disclose information that results from your
						use of our Services. We use your data to provide and improve Service. By using Service,
						you agree to the collection and use of information in accordance with this policy.
						Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy
						have the same meanings as in our Terms and Conditions. Our Terms and Conditions
						(“Terms”) govern all use of our Service and together with the Privacy Policy constitutes
						your agreement with us (“agreement”).
					</Typography>

					<Typography style={{fontWeight: 'bold'}}>2. Definitions</Typography>
					<ul
						style={{
							paddingLeft: isDesktop ? 40 : 20,
						}}
					>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								SERVICE means the fuelwallet.mycarviva.com, mycarviva.com website and Carviva App
								operated by CTL.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								PERSONAL DATA means data about a living individual who can be identified from those
								data (or from those and other information either in our possession or likely to come
								into our possession).
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								USAGE DATA is data collected automatically either generated using Service or from
								Service infrastructure itself (for example, the duration of a page visit).
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								COOKIES are small files stored on your device (computer or mobile device).
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							{' '}
							<Typography variant="p">
								DATA CONTROLLER means a natural or legal person who (either alone or jointly or in
								common with other persons) determines purposes for which and the way any personal
								data are, or are to be, processed. To this Privacy Policy, we are a Data Controller
								of your data.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							{' '}
							<Typography variant="p">
								DATA PROCESSORS (OR SERVICE PROVIDERS) mean any natural or legal person who
								processes the data on behalf of the Data Controller. We may use the services of
								various Service Providers to process your data more effectively.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							{' '}
							<Typography variant="p">
								DATA SUBJECT is any living individual who is the subject of Personal Data. THE USER
								is the individual using our Service. The User corresponds to the Data Subject, who
								is the subject of Personal Data.
							</Typography>
						</li>
					</ul>

					<Typography style={{fontWeight: 'bold'}}>3. Information Collection and Use</Typography>
					<Typography variant="p" paddingLeft={2}>
						We collect several different types of information for various purposes to provide and
						improve our Service to you.
					</Typography>

					<Typography style={{fontWeight: 'bold'}}>4. Types of Data Collected</Typography>
					<Typography variant="p" paddingLeft={2}>
						Personal Data
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						While using our Service, we may ask you to provide us with certain personally
						identifiable information that can be used to contact or identify you (“Personal Data”).
						Personally identifiable information may include, but is not limited to:
					</Typography>
					<ul
						style={{
							paddingLeft: isDesktop ? 40 : 20,
						}}
					>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">Email address</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">First name and last name</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">Mobile Phone number</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">Country, country phone code and City</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							{' '}
							<Typography variant="p">Cookies and Usage Data</Typography>
						</li>
					</ul>
					<Typography variant="p" paddingLeft={2}>
						We may use your Personal Data to contact you with newsletters, marketing or promotional
						materials and other information that may be of interest to you. You may opt out of
						receiving any, or all, of these communications from us by following the unsubscribe
						link.
					</Typography>

					<Typography variant="p" paddingLeft={2}>
						Usage Data
					</Typography>

					<Typography variant="p" paddingLeft={2}>
						We may also collect information that your browser sends whenever you visit our Service
						or when you access Service by or through any device (“Usage Data”). This Usage Data may
						include information such as your computer’s Internet Protocol address (e.g IP address),
						browser type, browser version, the pages of our Service that you visit, the time and
						date of your visit, the time spent on those pages, unique device identifiers and other
						diagnostic data. When you access Service with a device, this Usage Data may include
						information such as the type of device you use, your device unique ID, the IP address of
						your device, your device operating system, the type of Internet browser you use, unique
						device identifiers and other diagnostic data.
					</Typography>

					<Typography variant="p" paddingLeft={2}>
						Location Data
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may use and store information about your location if you give us permission to do so
						(“Location Data”). We use this data to provide features of our Service, to improve and
						customize our Service. You can enable or disable location services when you use our
						Service at any time by way of your device settings.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						Tracking & Cookies Data
					</Typography>

					<Typography variant="p" paddingLeft={2}>
						We use cookies and similar tracking technologies to track the activity on our Service
						and we hold certain information. Cookies are files with a small amount of data which may
						include an anonymous unique identifier. Cookies are sent to your browser from a website
						and stored on your device. Other tracking technologies are also used such as beacons,
						tags and scripts to collect and track information and to improve and analyze our
						Service. You can instruct your browser to refuse all cookies or to indicate when a
						cookie is being sent. However, if you do not accept cookies, you may not be able to use
						some portions of our Service.
					</Typography>

					<Typography variant="p" paddingLeft={2}>
						Examples of Cookies we use:
					</Typography>
					<ul
						style={{
							paddingLeft: isDesktop ? 40 : 20,
						}}
					>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								Session Cookies: We use Session Cookies to operate our Service.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								Preference Cookies: We use Preference Cookies to remember your preferences and
								various settings.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								Security Cookies: We use Security Cookies for security purposes.
							</Typography>
						</li>
					</ul>
					<Typography variant="p" paddingLeft={2}>
						Other Data
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						While using our Service, we may also collect the following information: sex, age, date
						of birth, telephone number (work, mobile)
					</Typography>

					<Typography style={{fontWeight: 'bold'}}>5. Use of Data</Typography>
					<Typography variant="p" paddingLeft={2}>
						Carviva uses the collected data for various purposes:
					</Typography>
					<ul
						style={{
							paddingLeft: isDesktop ? 40 : 20,
						}}
					>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">to provide and maintain our Service.</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">to notify you about changes to our Service.</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								to allow you to participate in interactive features of our Service when you choose
								to do so.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">to provide customer support.</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								to gather analysis or valuable information so that we can improve our Service.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">to monitor the usage of our Service.</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">to detect, prevent and address technical issues.</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								to fulfil any other purpose for which you provide it.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								to carry out our obligations and enforce our rights arising from any contracts
								entered between you and us, including for billing and collection.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								to provide you with notices about your account and/or subscription, including
								expiration and renewal notices, email-instructions, etc.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								to provide you with news, special offers and general information about other goods,
								services, and events which we offer that are similar to those that you have already
								purchased or enquired about unless you have opted not to receive such information.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								in any other way we may describe when you provide the information.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">for any other purpose with your consent.</Typography>
						</li>
					</ul>

					<Typography style={{fontWeight: 'bold'}}>6. Retention of Data</Typography>
					<Typography variant="p" paddingLeft={2}>
						We will retain your Personal Data only for as long as is necessary for the purposes set
						out in this Privacy Policy. We will retain and use your Personal Data to the extent
						necessary to comply with our legal obligations (for example, if we are required to
						retain your data to comply with applicable laws), resolve disputes, and enforce our
						legal agreements and policies. We will also retain Usage Data for internal analysis
						purposes. Usage Data is generally retained for a shorter period, except when this data
						is used to strengthen the security or to improve the functionality of our Service, or we
						are legally obligated to retain this data for longer time periods.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>7. Transfer of Data</Typography>
					<Typography variant="p" paddingLeft={2}>
						Your information, including Personal Data, may be transferred to and maintained on
						computers located outside of your state, province, country, or other governmental
						jurisdiction where the data protection laws may differ from those of your jurisdiction.
						If you are located outside Nigeria and choose to provide information to us, please note
						that we transfer the data, including Personal Data, to Nigeria and process it there.
						Your consent to this Privacy Policy followed by your submission of such information
						represents your agreement to that transfer. Carviva will take all the steps reasonably
						necessary to ensure that your data is treated securely and in accordance with this
						Privacy Policy and no transfer of your Personal Data will take place to an organization
						or a country unless there are adequate controls in place including the security of your
						data and other personal information.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>8. Disclosure of Data</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may disclose personal information that we collect, or you provide:
					</Typography>
					<ul
						style={{
							paddingLeft: isDesktop ? 40 : 20,
						}}
					>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								Disclosure for Law Enforcement: Under certain circumstances, we may be required to
								disclose your Personal Data if required to do so by law or in response to valid
								requests by public authorities.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								Business Transaction: If we or our subsidiaries are involved in a merger,
								acquisition or asset sale, your Personal Data may be transferred.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								Other cases. We may disclose your information also:
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">to our subsidiaries and affiliates.</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								to contractors, service providers, and other third parties we use to support our
								business.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">to fulfil the purpose for which you provide it.</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								for the purpose of including your company’s logo on our website.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								for any other purpose disclosed by us when you provide the information.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">with your consent in any other cases.</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								if we believe disclosure is necessary or appropriate to protect the rights,
								property, or safety of the Company, our customers, or others.
							</Typography>
						</li>
					</ul>
					<Typography style={{fontWeight: 'bold'}}>9. Security of Data</Typography>
					<Typography variant="p" paddingLeft={2}>
						The security of your data is important to us but remember that no method of transmission
						over the Internet or method of electronic storage is 100% secure. While we strive to use
						commercially acceptable means to protect your Personal Data, we cannot guarantee its
						absolute security.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>
						10. Data Protection Rights Under General Data Protection Regulation (GDPR)
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						If you are a resident of the European Union (EU) and European Economic Area (EEA), you
						have certain data protection rights, covered by GDPR. We aim to take reasonable steps to
						allow you to correct, amend, delete, or limit the use of your Personal Data. If you wish
						to be informed what Personal Data, we hold about you and if you want it to be removed
						from our systems, please email us at:
						<Link sx={{color: 'primary.main'}} href="mailto:info@mycarviva.com">
							{' '}
							info@mycarviva.com
						</Link>
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						In certain circumstances, you have the following data protection rights:
					</Typography>
					<ul
						style={{
							paddingLeft: isDesktop ? 40 : 20,
						}}
					>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								the right to access, update or to delete the information we have on you.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								the right of rectification. You have the right to have your information rectified if
								that information is inaccurate or incomplete.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								the right to object. You have the right to object to our processing of your Personal
								Data.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								the right of restriction. You have the right to request that we restrict the
								processing of your personal information.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								the right to data portability. You have the right to be provided with a copy of your
								Personal Data in a structured, machine-readable, and commonly used format.
							</Typography>
						</li>
						<li
							style={{
								paddingLeft: isDesktop ? 20 : 10,
								marginBottom: 20,
							}}
						>
							<Typography variant="p">
								the right to withdraw consent. You also have the right to withdraw your consent at
								any time where we rely on your consent to process your personal information.
							</Typography>
						</li>
					</ul>
					<Typography variant="p" paddingLeft={2}>
						Please note that we may ask you to verify your identity before responding to such
						requests. Please note, we may be unable to provide Service without some necessary data.
						You have the right to complain to a Data Protection Authority about our collection and
						use of your Personal Data. For more information, please contact your local data
						protection authority in the European Economic Area (EEA).
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>11. Service Providers</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may employ third party companies and individuals to facilitate our Service (“Service
						Providers”), provide Service on our behalf, perform Service-related services, or assist
						us in analyzing how our Service is used. These third parties have access to your
						Personal Data only to perform these tasks on our behalf and are obligated not to
						disclose or use it for any other purpose.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>12. Analytics</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may use third-party Service Providers to monitor and analyze the use of our Service.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>13. CI/CD tools</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may use third-party Service Providers to automate the development process of our
						Service.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>14. Advertising</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may use third-party Service Providers to show advertisements to you to help support
						and maintain our Service.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>15. Behavioral Remarketing</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may use remarketing services to advertise on third party websites to you after you
						visited our Service. We and our third-party vendors use cookies to inform, optimize and
						serve ads based on your past visits to our Service.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>16. Payments</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may provide paid products and/or services within Service. In that case, we use
						third-party services for payment processing (e.g. payment processors). We will not store
						or collect your payment card details. That information is provided directly to our
						third-party payment processors whose use of your personal information is governed by
						their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as
						managed by the PCI Security Standards Council, which is a joint effort of brands like
						Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the
						secure handling of payment information.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>17. Links to Other Sites</Typography>
					<Typography variant="p" paddingLeft={2}>
						Our Service may contain links to other sites that are not operated by us. If you click a
						third-party link, you will be directed to that third party’s site. We strongly advise
						you to review the Privacy Policy of every site you visit. We have no control over and
						assume no responsibility for the content, privacy policies or practices of any
						third-party sites or services.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>18. Links to Other Sites</Typography>
					<Typography variant="p" paddingLeft={2}>
						Our Service may contain links to other sites that are not operated by us. If you click a
						third-party link, you will be directed to that third party’s site. We strongly advise
						you to review the Privacy Policy of every site you visit. We have no control over and
						assume no responsibility for the content, privacy policies or practices of any
						third-party sites or services.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>19. Children’s Privacy</Typography>
					<Typography variant="p" paddingLeft={2}>
						Our Services are not intended for use by children under the age of 18 (“Child” or
						“Children”). We do not knowingly collect personally identifiable information from
						Children under 18. If you become aware that a Child has provided us with Personal Data,
						please contact us. If we become aware that we have collected Personal Data from Children
						without verification of parental consent, we take steps to remove that information from
						our servers.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>20. Changes to This Privacy Policy</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may update our Privacy Policy from time to time. We will notify you of any changes by
						posting the new Privacy Policy on this page. We will let you know via email and/or a
						prominent notice on our Service, prior to the change becoming effective and update
						“effective date” at the top of this Privacy Policy. You are advised to review this
						Privacy Policy periodically for any changes. Changes to this Privacy Policy are
						effective when they are posted on this page.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>21. Contact Us</Typography>
					<Typography variant="p" paddingLeft={2}>
						If you have any questions about this Privacy Policy, please contact us by email:
						<Link sx={{color: 'primary.main'}} href="mailto:info@mycarviva.com">
							{' '}
							info@mycarviva.com
						</Link>{' '}
						This Privacy Policy was created and last updated on 2023-03-03
					</Typography>
				</Stack>
			</DialogContent>
			{/* <DialogTitle id="alert-dialog-slide-title"></DialogTitle> */}
		</Dialog>
	);
}
