import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container } from '@mui/material';

// import { ReactComponent as BLogo } from 'src/assets/black-logo.svg';
// config
import { HEADER } from '../../config';
// components
// import Logo from '../../components/Logo';

import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
// import useResponsive from 'src/hooks/useResponsive';
import useOffSetTop from 'src/hooks/useOffSetTop';
import cssStyles from 'src/utils/cssStyles';
import Logo from 'src/components/Logo';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

//   const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  return (
		<AppBar sx={{boxShadow: 0, bgcolor: 'transparent'}}>
			<ToolbarStyle
				disableGutters
				sx={{
					...(isOffset && {
						...cssStyles(theme).bgBlur(),
						height: {md: HEADER.MAIN_DESKTOP_HEIGHT - 16},
					}),
				}}
			>
				<Container
					sx={{
						display: 'flex',
						alignItems: 'center',
					  justifyContent: 'space-between',

					}}
				>
					{/* {(isDesktop || isOffset) && (
						<img src={require('src/assets/logo.png')} width={'188'} height={'52'}  alt="Carviva"/>
					)} */}
					<Logo />

					<Box sx={{flexGrow: 1}} />

					{/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}

				  <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
				</Container>
			</ToolbarStyle>

			{isOffset && <ToolbarShadowStyle />}
		</AppBar>
	);
}
