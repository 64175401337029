import * as Yup from 'yup';
import {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';

// material
import {Link, Stack, IconButton, InputAdornment, Alert} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import {RHFTextField, FormProvider} from 'src/components/hook-form';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import useAuth from 'src/hooks/useAuth';
import {PATH_AUTH} from 'src/path';
// ----------------------------------------------------------------------

export default function LoginForm() {
	const {login} = useAuth();
	const [showPassword, setShowPassword] = useState(false);

	const LoginSchema = Yup.object().shape({
		phone_number: Yup.string().required('Phone Number is required'),
		password: Yup.string().required('Password is required'),
	});

	const defaultValues = {
		phone_number: '',
		password: '',
		// remember: true,
	};

	const methods = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		reset,
		setError,
		handleSubmit,
		watch,
		formState: {errors, isSubmitting},
	} = methods;

	const values = watch();

	const onSubmit = async (data) => {
		try {
			await login(values.phone_number, values.password);
		} catch (error) {
			console.error(error);
			reset();
			setError('afterSubmit', error);
			//  if (isMountedRef.current) {
			//    setError('afterSubmit', error);
			//  }
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				{!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.error}</Alert>}
				<RHFTextField name="phone_number" label="Phone Number" />
				<RHFTextField
					name="password"
					label="Password"
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
									<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
				{/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

				<Link
					component={RouterLink}
					variant="subtitle2"
					to={PATH_AUTH.forgotPassword}
					underline="hover"
				>
					Forgot password?
				</Link>
			</Stack>

			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				loading={isSubmitting}
			>
				Login
			</LoadingButton>
		</FormProvider>
	);
}
