import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
	Box,
	Divider,
	Typography,
	Stack,
	MenuItem,
	IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
// mocks_

import useAuth from "src/hooks/useAuth";
import MyAvatar from "src/components/MyAvatar";
import { PATH_AUTH, PATH_DASHBOARD } from "src/path";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
	{
		label: "Home",
		icon: "eva:home-fill",
		linkTo: "/",
	},
	{
		label: "Settings",
		icon: "eva:settings-2-fill",
		linkTo: PATH_DASHBOARD.settings,
	},
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
	const anchorRef = useRef(null);
	const { logout, user } = useAuth();
	const [open, setOpen] = useState(null);

	const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  
  const { enqueueSnackbar } = useSnackbar();
	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	const handleLogout = async () => {
		try {
			await logout();
			navigate(PATH_AUTH.login, { replace: true });

			if (isMountedRef.current) {
				handleClose();
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar("Unable to logout!", { variant: "error" });
		}
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
					p: 0,
					...(open && {
						"&:before": {
							zIndex: 1,
							content: "''",
							width: "100%",
							height: "100%",
							borderRadius: "50%",
							position: "absolute",
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}
			>
				<MyAvatar />
			</IconButton>

			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{
					p: 0,
					mt: 1.5,
					ml: 0.75,
					"& .MuiMenuItem-root": {
						typography: "body2",
						borderRadius: 0.75,
					},
				}}
			>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant="subtitle2" noWrap>
						{user.first_name} {user.last_name}
					</Typography>
					<Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
						{user.email}
					</Typography>
				</Box>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Stack sx={{ p: 1 }}>
					{MENU_OPTIONS.map((option) => (
						<MenuItem
							key={option.label}
							to={option.linkTo}
							component={RouterLink}
							onClick={handleClose}
						>
							{option.label}
						</MenuItem>
					))}
				</Stack>

				<Divider sx={{ borderStyle: "dashed" }} />

				<MenuItem onClick={handleLogout} sx={{ m: 1 }}>
					Logout
				</MenuItem>
			</MenuPopover>
		</>
	);
}
