import { Link as RouterLink } from 'react-router-dom';
// @mui

import { styled } from '@mui/material/styles';
import {  Link, Container, Typography, Stack } from '@mui/material';
// hooks

import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';

// sections
import { LoginForm } from '../sections/auth/login';
// import AuthSocial from '../sections/auth/AuthSocial';
import { PATH_AUTH } from '../path';
// import RainOil from "src/assets/logo.png";
// import Image from 'src/components/Image';
import Logo from 'src/components/Logo';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'flex-end',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));



const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  return (
		<Page title="Login">
			<RootStyle>
				<HeaderStyle>
					{smUp && (
						<Typography variant="body2" sx={{ mt: { md: -2 } }}>
							Don’t have an account? {""}
							<Link
								variant="subtitle2"
								component={RouterLink}
								to={PATH_AUTH.register}
							>
								Get started
							</Link>
						</Typography>
					)}
				</HeaderStyle>

				<Container >
					<ContentStyle>
						<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
							{/* <Tooltip title={capitalCase(method)} placement="right"> */}
						  <>
							  <Logo />
								{/* <Image disabledEffect src={RainOil} /> */}
							</>
							{/* </Tooltip> */}
						</Stack>
						<Typography variant="h4" gutterBottom>
							Sign in to your account
						</Typography>

						<LoginForm />

						{!smUp && (
							<Typography variant="body2" align="center" sx={{ mt: 3 }}>
								Don’t have an account?{" "}
								<Link
									variant="subtitle2"
									component={RouterLink}
									to={PATH_AUTH.register}
								>
									Get started
								</Link>
							</Typography>
						)}
					</ContentStyle>
				</Container>
			</RootStyle>
		</Page>
	);
}
