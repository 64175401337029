function path(root, sublink) {
	return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

export const PATH_AUTH = {
	root: ROOTS_AUTH,
	login: path(ROOTS_AUTH, "/login"),
	register: path(ROOTS_AUTH, "/register"),
	forgotPassword: path(ROOTS_AUTH, "/forgot-password"),
	verify: path(ROOTS_AUTH, "/verify"),
	preRegister: path(ROOTS_AUTH, "/preregister"),
	twoFactor: path(ROOTS_AUTH, "/two-factor"),
};

export const PATH_PAGE = {
	comingSoon: '/coming-soon',
	maintenance: '/maintenance',
	pricing: '/pricing',
	payment: '/payment',
	about: '/about-us',
	contact: '/contact-us',
	faqs: '/faqs',
	page404: '/404',
	page500: '/500',
	components: '/components',
};
export const PATH_DASHBOARD = {
	root: ROOTS_DASHBOARD,
	app: path(ROOTS_DASHBOARD, "/app"),
	transactions: path(ROOTS_DASHBOARD, "/transactions"),
	linkWallet: path(ROOTS_DASHBOARD, "/link_wallet"),
	addFuelBeneficiary: path(ROOTS_DASHBOARD, "/beneficiary"),
	addCars: path(ROOTS_DASHBOARD, "/cars"),
	fuelTokens: path(ROOTS_DASHBOARD, "/tokens"),
	settings: path(ROOTS_DASHBOARD, "/settings"),
	fleetManagement: {
		root: path(ROOTS_DASHBOARD, "/fleet-management"),
		drivers: path(ROOTS_DASHBOARD, "/fleet-management/drivers"),
		driversByID: path(ROOTS_DASHBOARD, "/fleet-management/drivers/:id"),
		staffs: path(ROOTS_DASHBOARD, "/fleet-management/staff"),
		subFleets: path(ROOTS_DASHBOARD, '/fleet-management/sub-fleets'),
		staffsByID: path(ROOTS_DASHBOARD, "/fleet-management/staff/:id"),
		addDriver: path(ROOTS_DASHBOARD, "/fleet-management/drivers/add"),
	},
	autocare: {
		root: path(ROOTS_DASHBOARD, "/autocare"),
		booking: {
			root: path(ROOTS_DASHBOARD, "/booking"),
			buyFuel: path(ROOTS_DASHBOARD, "/booking/buy-fuel"),
			buyOil: path(ROOTS_DASHBOARD, "/booking/buy-oil"),
			carScan: path(ROOTS_DASHBOARD, "/booking/car-scan"),
			carWash: path(ROOTS_DASHBOARD, "/booking/car-wash"),
			homeService: path(ROOTS_DASHBOARD, "/booking/home-service"),
			oilChange: path(ROOTS_DASHBOARD, "/booking/oil-change"),
			paintCar: path(ROOTS_DASHBOARD, "/booking/paint-car"),
			repairs: path(ROOTS_DASHBOARD, "/booking/repairs"),
			roadside: path(ROOTS_DASHBOARD, "/booking/roadside"),
			wheelsBooking: path(ROOTS_DASHBOARD, "/booking/wheels-booking"),
		},
		instantQuote: {
			root: path(ROOTS_DASHBOARD, "/instant-quote"),
			lubricants: path(ROOTS_DASHBOARD, "/instant-quote/lubricants"),
			tyres: path(ROOTS_DASHBOARD, "/instant-quote/tyres"),
			batteries: path(ROOTS_DASHBOARD, "/instant-quote/batteries"),
			spareParts: path(ROOTS_DASHBOARD, "/instant-quote/spare-parts"),
		}
	},
};
