import jwtDecode from 'jwt-decode';
//
import axios from './axios';
import storage from './storage';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};


const encodeString = (str) => {
	return btoa(
		encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
			String.fromCharCode(parseInt(p1, 16))
		)
	);
};

const decodeString = (str) => {
	// eslint-disable-next-line prefer-template
	return decodeURIComponent(
		Array.prototype.map
			.call(
				atob(str),
				(c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
			)
			.join("")
	);
};
//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

// ----------------------------------------------------------------------

const setSession = (accessToken) => {
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    storage.clear()
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, decodeString, encodeString };
