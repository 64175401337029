import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import {Box} from '@mui/material';
// import { ReactComponent as ILogo } from 'src/assets/inverted.svg'
import LogoB from 'src/assets/logo.png';
// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({disabledLink = false, sx}) {
	// const PRIMARY_LIGHT = theme.palette.primary.light;

	// const PRIMARY_MAIN = theme.palette.primary.main;

	// const PRIMARY_DARK = theme.palette.primary.dark;

	// OR
	// const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

	const logo = (
		<Box sx={{...sx}}>
			{/* <ILogo /> */}

			<img
				// width={92}
				height={30}
				src={LogoB}
				alt="carviva"
			/>
			{/* <WLogo /> */}
		</Box>
	);

	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="/">{logo}</RouterLink>;
}
