import React from 'react';
import {
	Typography,
	Stack,
	Dialog,
	DialogContent,
	DialogTitle,
	AppBar,
	Toolbar,
	IconButton,
} from '@mui/material';


// import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------
import Slide from '@mui/material/Slide';
import Iconify from 'src/components/Iconify';
import useResponsive from 'src/hooks/useResponsive';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Terms({openDialog1, handleClick1}) {
	const isDesktop = useResponsive('up', 'md');
	return (
		<Dialog
			open={openDialog1}
			TransitionComponent={Transition}
			// keepMounted
			onClose={handleClick1}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			fullScreen
			keepMounted
			// fullWidth
			maxWidth="lg"
		>
			<AppBar sx={{position: 'relative'}}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClick1} aria-label="close">
						<Iconify icon={'material-symbols:close'} />
					</IconButton>
					<Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
						Terms and Conditions
					</Typography>
				</Toolbar>
			</AppBar>

			<DialogTitle sx={{textAlign: 'center'}}>
				CARVIVA FUEL WALLET APPLICATION AND CARVIVA MOBILE APPLICATION END-USER LICENSE AGREEMENT
				<br />
			</DialogTitle>
			<Typography sx={{textAlign: 'center'}} variant="p">
				THIS AGREEMENT is dated 3rd March, 2023
			</Typography>
			<DialogContent
				sx={{
					width: isDesktop ? '100%' : '100%',
					paddingLeft: isDesktop ? '10%' : '',
					paddingRight: isDesktop ? '10%' : '',
				}}
			>
				<Stack gap={2}>
					<Typography variant="p">PLEASE READ CAREFULLY BEFORE DOWNLOADING THE APP</Typography>
					<Typography variant="p">
						This end-user licence agreement (EULA) is between you (End-user) and CARVIVA
						TECHNOLOGIES LIMITED of HUB 43, CMD Road, Lagos, Nigeria (Licensor, us or we) for the
						entire suite of CARVIVA mobile App & Fuel Wallet web application software services
						(App);
					</Typography>
					<Typography variant="p">
						For our CARVIVA Mobile App, we license to you based on this EULA and subject to any
						rules or policies applied by the Google Android application store from whose site,
						located at https://play.google.com/store/apps (Playstore)
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>Important notice:</Typography>
					<Typography variant="p" paddingLeft={2}>
						A. By creating a Fuel Wallet account, Downloading the Carviva Mobile App from the
						Playstore or clicking on the "REGISTER" button on the Fuel Wallet portal, you agree to
						the terms of the licence.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						B. If you do not agree to the terms of this licence, we will not license the App to you,
						and you must stop downloading or registering on the Fuel Wallet platform now.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						C. You are entitled to print a copy of this EULA for future referenc
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>Agreed terms:</Typography>
					<Typography style={{fontWeight: 'bold'}}>1. Acknowledgements:</Typography>
					<Typography variant="p" paddingLeft={2}>
						The terms of this EULA apply to the App or any of the services accessible through the
						App (Services), including any updates or supplements to the App or any Service, unless
						they come with separate terms, in which case those terms apply. If any open-source
						software is included in the App or any Service, the terms of an open-source licence may
						override some of the terms of this EULA.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may change these terms at any time by sending you an SMS/ email notification with
						details of the change or notifying you of a change when you next start the App or login
						to your Fuel Wallet dashboard. The new terms may be displayed on-screen, and you may be
						required to read and accept them to continue your use of the Services.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						From time-to-time updates to the Carviva App may be issued through the Playstore.
						Depending on the update for the Mobile App, you may not be able to use the Services
						until you have downloaded or streamed the latest version of the App and accepted any new
						terms.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						You will be assumed to have obtained permission from the owners of the mobile telephone
						or handheld devices that are controlled, but not owned, by you and described in 2.2(a)
						(Devices) and to download the App onto the Devices. You and they may be charged by your
						and their service providers for internet access on the Devices. You accept
						responsibility in accordance with the terms of this EULA for the use of the App or any
						Service on or in relation to any Device, whether or not it is owned by you.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						By using the App or any of the Services, you consent to us collecting and using
						technical information about the Devices and related software, hardware and peripherals
						for Services that are internet-based or wireless to improve our products and to provide
						any Services to you.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						The App or any Service may contain links to other independent third-party websites
						(Third-party Sites). Third-party Sites are not under our control, and we are not
						responsible for and do not endorse their content or their privacy policies (if any). You
						will need to make your own independent judgement regarding your interaction with any
						Third-party Sites, including the purchase and use of any products or services accessible
						through them.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						Any words following the terms including, include, in particular or for example or any
						similar phrase shall be construed as illustrative and shall not limit the generality of
						the related general words.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>2. Grant and scope of licence :</Typography>
					<Typography variant="p" paddingLeft={2}>
						In consideration of you agreeing to abide by the terms of this EULA, we grant you a
						non-transferable, non-exclusive licence to use the App on the Devices, subject to these
						terms, the Privacy Policy and the Appstore Rules, incorporated into this EULA by
						reference. We reserve all other rights.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						You may:
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(a) download or stream a copy of the App onto an Android device and to view, use and
						display the App on the Devices for your personal purposes only; and
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(b) use the Documents for your personal purposes only.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>2. Licence restrictions :</Typography>
					<Typography variant="p" paddingLeft={2}>
						Except as expressly set out in this EULA or as permitted by law, you agree:
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(a) not to copy the App or Documents except where such copying is incidental to normal
						use of the App, or where it is necessary for the purpose of back-up or operational
						security;
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(b) not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the
						App or Documents;
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(c) not to make alterations to, or modifications of, the whole or any part of the App,
						or permit the App or any part of it to be combined with, or become incorporated in, any
						other programs;
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(d) not to disassemble, decompile, reverse-engineer or create derivative works based on
						the whole or any part of the App or attempt to do any such thing except to the extent
						that) such actions cannot be prohibited because they are essential for the purpose of
						achieving inter-operability of the App with another software program, and provided that
						the information obtained by you during such activities:
					</Typography>
					<Typography variant="p" paddingLeft={6}>
						(i) is used only for the purpose of achieving inter-operability of the App with another
						software program.
					</Typography>
					<Typography variant="p" paddingLeft={6}>
						(ii) is not unnecessarily disclosed or communicated without our prior written consent to
						any third party; and
					</Typography>
					<Typography variant="p" paddingLeft={6}>
						(iii) is not used to create any software that is substantially similar to the App.
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(e) to keep all copies of the App secure and to maintain accurate and up-to-date records
						of the number and locations of all copies of the App.
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(f) to include our copyright notice on all entire and partial copies you make of the App
						on any medium.
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(g) not to provide or otherwise make available the App in whole or in part (including
						object and source code), in any form to any person without prior written consent from
						us; and
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(h) to comply with all technology control or export laws and regulations that apply to
						the technology used or supported by the App or any Service (Technology), together
						Licence Restrictions.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>4. Acceptable use restrictions :</Typography>
					<Typography variant="p" paddingLeft={2}>
						You must:
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(a) not use the App or any Service in any unlawful manner, for any unlawful purpose, or
						in any manner inconsistent with this EULA, or act fraudulently or maliciously, for
						example, by hacking into or inserting malicious code, including viruses, or harmful
						data, into the App, any Service or any operating system.
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(b) not infringe our intellectual property rights or those of any third party in
						relation to your use of the App or any Service (to the extent that such use is not
						licensed by this EULA);
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(c) not transmit any material that is defamatory, offensive or otherwise objectionable
						in relation to your use of the App or any Service.
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(d) not use the App or any Service in a way that could damage, disable, overburden,
						impair or compromise our systems or security or interfere with other users; and
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(e) not collect or harvest any information or data from any Service or our systems or
						attempt to decipher any transmissions to or from the servers running any Service.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>5. Intellectual property rights :</Typography>
					<Typography variant="p" paddingLeft={2}>
						You acknowledge that all intellectual property rights in the App, the Documents and the
						Technology anywhere in the world belong to us or our licensors, that rights in the App
						are licensed (not sold) to you, and that you have no rights in, or to, the App, the
						Documents or the Technology other than the right to use each of them in accordance with
						the terms of this EULA.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						You acknowledge that you have no right to have access to the App in source-code form.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>6. No warranty or support :</Typography>
					<Typography variant="p" paddingLeft={2}>
						You expressly acknowledge and agree that use of the App and any Documents and Services
						provided is at your sole risk and that the entire risk as to satisfactory quality,
						performance, accuracy and effort is with you.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						To the maximum extent permitted by applicable law, the App and Documents and Services
						are provided "as is" and “as available”, with all faults and without warranty of any
						kind, and we hereby disclaim all warranties and conditions with respect to the App,
						Documents and Services, either express, implied or statutory, including, but not limited
						to, any implied warranties and/or conditions of merchantability, of satisfactory
						quality, of fitness for a particular purpose, of accuracy, of quiet enjoyment, and
						non-infringement of third party rights. We do not warrant against interference with your
						enjoyment of the App or Documents, that the functions contained in the App or Services
						will meet your requirements, that the operation of the App or Services will be
						uninterrupted or error-free, or that defects in the App or Services will be corrected.
						No oral or written information or advice given by us or our authorised representative
						shall create a warranty. Should the App or Services prove defective, you assume the
						entire cost of all necessary servicing, repair or correction.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>7. Limitation of liability:</Typography>
					<Typography variant="p" paddingLeft={2}>
						You acknowledge that the App has not been developed to meet your individual
						requirements, and that it is therefore your responsibility to ensure that the facilities
						and functions of the App as described in the Documents meet your requirements.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						Our maximum aggregate liability under or in connection with this EULA (including your
						use of any Services) whether in contract, tort (including negligence) or otherwise,
						shall in all circumstances be limited to GBP30. This does not apply to the types of loss
						set out in condition 7.47.4.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						Nothing in this EULA shall limit or exclude our liability for:
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(a) death or personal injury resulting from our negligence.
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(b) fraud or fraudulent misrepresentation; and
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(c) any other liability that cannot be excluded or limited by English law.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>8. Termination:</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may terminate this EULA immediately by written notice to you:
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(a) if you commit a material or persistent breach of this EULA which you fail to remedy
						(if remediable) within 14 days after the service of written notice requiring you to do
						so;
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(b) if you breach any of the Licence Restrictions or the Acceptable Use Restrictions;
						and
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(c) on thirty days’ notice.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						On termination for any reason:
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(a) all rights granted to you under this EULA shall cease;
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(b) you must immediately cease all activities authorised by this EULA;
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(c) you must immediately delete or remove the App from all Devices, and immediately
						destroy all copies of the App and Documents then in your possession, custody or control
						and certify to us that you have done so.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>
						9. Communication between the parties :
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						If you wish to contact us in writing, or if any condition in this EULA requires you to
						give us notice in writing, you can send this to us by e-mail at hello@thisummah.com, we
						will confirm receipt of this by contacting you in writing, normally by e-mail.
					</Typography>

					<Typography variant="p" paddingLeft={2}>
						If we have to contact you or give you notice in writing, we will do so by e-mail to the
						email address you provide to us in your request for the App.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>10. Events outside our control:</Typography>
					<Typography variant="p" paddingLeft={2}>
						We will not be liable or responsible for any failure to perform, or delay in performance
						of, any of our obligations under this EULA that is caused by any act or event beyond our
						reasonable control, including failure of public or private telecommunications networks
						(Event Outside Our Control).
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						If an Event Outside Our Control takes place that affects the performance of our
						obligations under this EULA:
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(a) our obligations under this EULA will be suspended and the time for performance of
						our obligations will be extended for the duration of the Event Outside Our Control; and
					</Typography>
					<Typography variant="p" paddingLeft={4}>
						(b) we will use our reasonable endeavours to find a solution by which our obligations
						under this EULA may be performed despite the Event Outside Our Control.
					</Typography>
					<Typography style={{fontWeight: 'bold'}}>11. Other important terms :</Typography>
					<Typography variant="p" paddingLeft={2}>
						We may transfer our rights and obligations under this EULA to another organisation, but
						this will not affect your rights or our obligations under this EULA.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						You may only transfer your rights or obligations under this EULA to another person if we
						agree in writing.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						If we fail to insist that you perform any of your obligations under this EULA, or if we
						do not enforce our rights against you, or if we delay in doing so, that will not mean
						that we have waived our rights against you and will not mean that you do not have to
						comply with those obligations. If we do waive a default by you, we will only do so in
						writing, and that will not mean that we will automatically waive any later default by
						you.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						Each of the conditions of this EULA operates separately. If any court or competent
						authority decides that any of them are unlawful or unenforceable, the remaining
						conditions will remain in full force and effect.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						Please note that this EULA, its subject matter and its formation, are governed by
						Nigerian law.
					</Typography>
					<Typography variant="p" paddingLeft={2}>
						In the event of any allegation of breach or question of interpretation relating to the
						Agreement or any other dispute in respect thereof the parties shall meet and negotiate
						in good faith to settle the matter amicably. If the parties are unable to settle the
						matter by Negotiation, within 30 (thirty) days of the declaration of such dispute, then,
						upon notice by either party to the other, the disputes, claims, questions or differences
						shall be referred to confidential mediation. Failing the ability of the parties to
						resolve the dispute through mediation, the dispute shall be submitted to arbitration.
						The arbitration proceeding shall be conducted in Lagos, Nigeria in English language and
						shall be governed by the Arbitration and Conciliation Act, Cap A18 Laws of the
						Federation of Nigeria, 2004 or any replacement thereof. The Arbitral Tribunal shall
						consist of a sole Arbitrator to be appointed mutually by the parties or failing which,
						by the Chairman, Chartered Institute of Arbitrators, UK (Nigeria Branch). The Arbitral
						Tribunal shall resolve the question submitted, award the relief to which each party may
						be entitled and allocate the costs of arbitration. The arbitration award issued by the
						Arbitral Tribunal shall be, final and binding on the parties, and fully enforced by any
						court having jurisdiction over the necessary party or its assets.
					</Typography>

					<Typography style={{fontWeight: 'bold'}}>
						{' '}
						This agreement has been entered into on the 3rd day of March, 2023.
					</Typography>
				</Stack>
			</DialogContent>
			{/* <DialogTitle id="alert-dialog-slide-title"></DialogTitle> */}
		</Dialog>
	);
}
