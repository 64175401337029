import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { PATH_AUTH, PATH_DASHBOARD } from "../path";
// hooks
import useAuth from "../hooks/useAuth";
// routes

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
	children: PropTypes.node,
};

export default function GuestGuard({ children }) {
	const { isAuthenticated, twoFactorEnabled } = useAuth();

	if (isAuthenticated) {
		return <Navigate to={PATH_DASHBOARD.root} />;
	}
	if (twoFactorEnabled && !isAuthenticated) {
		return <Navigate to={PATH_AUTH.twoFactor}   />;
	}

	return <>{children}</>;
}
