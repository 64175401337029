import {Outlet} from 'react-router-dom';

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
	return (
		<>
			<Outlet />
		</>
	);
}
