// component
import {PATH_DASHBOARD} from 'src/path';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
	{
		title: 'dashboard',
		path: PATH_DASHBOARD.app,
		icon: getIcon('ic:twotone-dashboard'),
	},
	{
		title: 'Instant Quote',
		path: PATH_DASHBOARD.autocare.instantQuote.root,
		icon: getIcon('tabler:brand-booking'),
	},
	// {
	// 	title: "Transactions",
	// 	path: PATH_DASHBOARD.transactions,
	// 	icon: getIcon("ion:analytics-outline"),
	// },
	// {
	// 	title: "Fuel Tokens",
	// 	path: PATH_DASHBOARD.fuelTokens,
	// 	icon: getIcon("ic:baseline-generating-tokens"),
	// },
	// {
	// 	title: "Link Wallet",
	// 	path: PATH_DASHBOARD.linkWallet,
	// 	icon: getIcon("fa6-solid:wallet"),
	// },
	// {
	// 	title: "Fuel Beneficiary",
	// 	path: PATH_DASHBOARD.addFuelBeneficiary,
	// 	icon: getIcon("fa6-solid:users-rays"),
	// },
	// {
	// 	title: "My Garage",
	// 	path: PATH_DASHBOARD.addCars,
	// 	icon: getIcon("ant-design:car-filled"),
	// },
	// {
	// 	title: "Fleet Manager",
	// 	icon: getIcon("carbon:inventory-management"),
	// 	path: PATH_DASHBOARD.fleetManagement.root,
	// 	children: [
	// 		{ title: "List Drivers", path: PATH_DASHBOARD.fleetManagement.drivers },
	// 		{ title: "List Staffs", path: PATH_DASHBOARD.fleetManagement.staffs },
	// 		{ title: "List Sub fleet", path: PATH_DASHBOARD.fleetManagement.subFleets, restrict: true}
	// 		// { title: "Add Driver", path: PATH_DASHBOARD.fleetManagement.addDriver },
	// 	],
	// },
	// {
	// 	title: 'Autocare Services',
	// 	icon: getIcon('tabler:brand-booking'),
	// 	path: PATH_DASHBOARD.autocare.root,
	// 	children: [
	// 		{title: 'Autocare Booking', path: PATH_DASHBOARD.autocare.booking.root},
	// 		{title: 'Instant Quote', path: PATH_DASHBOARD.autocare.instantQuote.root},
	// 		// { title: "Add Driver", path: PATH_DASHBOARD.fleetManagement.addDriver },
	// 	],
	// },
	// {
	// 	title: "user",
	// 	path: "/dashboard/user",
	// 	icon: getIcon("eva:people-fill"),
	// },
	// {
	// 	title: "product",
	// 	path: "/dashboard/products",
	// 	icon: getIcon("eva:shopping-bag-fill"),
	// },
	// {
	// 	title: "blog",
	// 	path: "/dashboard/blog",
	// 	icon: getIcon("eva:file-text-fill"),
	// },

	// {
	// 	title: "Not found",
	// 	path: "/404",
	// 	icon: getIcon("eva:alert-triangle-fill"),
	// },
];

export default navConfig;
